import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EventList from './EventList'
import GiftList from './GiftList'
import Event from './Event'

import {
  Button,
  Container,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Grid,
  List,
  Header
} from 'semantic-ui-react'

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class AppFrame extends Component {
  selected = 'events'
  state = {}
  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })
  render() {
    const { sidebarOpened } = this.state
    const { children } = this.props
    return (
      <div>
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Menu size='large' borderless>
          <Container>
            { children.slice(0, -1) }
          </Container>
        </Menu>
        { children.slice(-1)[0] }
      </Responsive>
      <Responsive as={Sidebar.Pushable} getWidth={getWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation='push' onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
        { children.slice(0, -1) }
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment textAlign='center' vertical>
            <Container>
              <Menu secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          { children.slice(-1)[0] }
        </Sidebar.Pusher>
      </Responsive>
      <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header inverted as='h4' content='Մեր մասին' />
              <List link inverted>
                <List.Item as='a'>Կապ</List.Item>
                <List.Item as='a'>Օգտվելու կանոննոր</List.Item>
                <List.Item as='a'>Գաղտնիության քաղաքականություն</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h4' inverted>
              © 2019 Lollipop.am
              </Header>
              <p>
              Բոլոր իրավունքները պաշտպանված են
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
      </div>
    )
  }
}

class Home extends Component {
  render() {
    const { match } = this.props;
    const selected = match.path.slice(1);
    return (
      <AppFrame>
        <Menu.Item><Image src='/lollipop.png' size='mini' /></Menu.Item>
        <Menu.Item as={Link} to='events' active={selected==='events'}>Միջոցառումներ</Menu.Item>
        <Menu.Item as={Link} to='gifts' active={selected==='gifts'}>Նվերներ</Menu.Item>
        <Menu.Item position='right'><Button as='a' onClick={this.logOut}>Ելք</Button></Menu.Item>
        <Router>
          <div>
            <Route exact path="/" component={EventList} />
            <Route exact path="/events" component={EventList} />
            <Route path="/gifts"  component={GiftList} />
            <Route path="/events/:id" component={Event} />
          </div>
        </Router>
      </AppFrame>
    )
  }
}

export default Home
