import React, { Component } from 'react'
import app from "./base";

import {
  Card,
  Container
} from 'semantic-ui-react'

class GiftList extends Component {
  constructor(props) {
    super(props);
    this.state = {items:[]};
  }
  componentWillMount() {
    const db = app.firestore();
    db.collection('products').onSnapshot(function(dataSnapshot) {
      var items = [];
      dataSnapshot.forEach(function(ds) {
        var data = ds.data();
        data['id'] = ds.id;
        items.push(data);
      });
      this.setState({
        items: items
      });
    }.bind(this));

  }
  render() {
    return (
      <Container>
        <Card.Group itemsPerRow={4}>
        { this.state.items.map(function(item){
         return  (<Card
            image={item.image}
            header={item.name}
            meta={item.url}
          />)
        }, this)}
        </Card.Group>
      </Container>    
    )
  }
}

export default GiftList;