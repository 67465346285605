import React, {Component} from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router";
import firebase from "firebase";
import app from "./base";
var provider = new firebase.auth.FacebookAuthProvider();
var currentUser = app.auth().currentUser;


class LoginForm extends Component {
  state = {
    redirect: false
  }  
  handleLogIn = async event => {
    var that = this;
    console.log('handleLogIn');
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      const user = await app
        .auth()
        .signInWithEmailAndPassword(email.value, password.value);
      that.setState({ redirect: true });
    } catch (error) {
      alert(error.message);
    }
  };

  handleFacebook = async event => {
    var that = this;
    try {
      const result = await app.auth().signInWithPopup(provider);
    
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // ...
      that.setState({ redirect: true });
    } catch(error) {
      alert(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      console.log(errorMessage);
    };
  } 

  render() {
    const { redirect } = this.state;

    if (currentUser)
      console.log('user:' + currentUser.email);

    if (redirect) {
      return <Redirect to='/'/>;
    }

    return (
  <div className='login-form'>
    {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
    <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
    <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2'textAlign='center'>
          <Image src='/lollipop.png' /> Log-in to your account
        </Header>
        <Form size='large' onSubmit={this.handleLogIn}>
          <Segment stacked>
            <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' name='email' />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              name='password'
            />

            <Button primary fluid size='large'>
              Login
            </Button>
          </Segment>
        </Form>
        <Button color='facebook' onClick={this.handleFacebook}>
      <Icon name='facebook' /> Login with Facebook
    </Button>
        <Message>
          New to us? <a href='/signup'>Sign Up</a>
        </Message>
      </Grid.Column>
    </Grid>
  </div>
    )
  }
}

export default withRouter(LoginForm)
