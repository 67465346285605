
import React, {Component} from "react"
import app from "./base"

import {
  Card,
  Container,
  Header
} from 'semantic-ui-react'


export default class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {text:'', items:[]};
  }  
  componentDidMount() {
    const id = this.props.match.params.id;
    const db = app.firestore();
    db.collection('events').doc(id).onSnapshot(ds => {
      const data = ds.data();
      this.setState({text:data.header});
    });
    var products = [];
    db.collection('products').where('event','==',id).onSnapshot(function(dataSnapshot) {
      dataSnapshot.forEach(function(ds) {
        var data = ds.data();
        products.push(data);
      });
      this.setState({items:products});
    }.bind(this));
  }
  render() {
    return (
      <Container >
        <Header>{this.state.text}</Header>
        {this.state.items.length > 0 ?
        (<Card.Group itemsPerRow={4}>
        { this.state.items.map(function(item){
         return  (<Card
            image={item.image}
            header={item.name}
            meta={item.url}
          />)
        }, this)}
        </Card.Group>) : <p>Empty</p>
        }
      </Container>    
    );
  }
}
