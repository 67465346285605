import React, { Component } from 'react'
import app from "./base";
import { Link } from "react-router-dom";

import {
  Card,
  Container,
  Button,
  Modal,
  Image,
  Form,
  Grid,
  Checkbox,
  TextArea,
  Icon,
  Dropdown
} from 'semantic-ui-react'

class EventItem extends Component {
  constructor(props) {
    super(props);
    this.state = {hover:false};
  }
  toggleHover = () => {
    this.setState({hover: !this.state.hover});
  }
  render() {
    return <Card onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
    <Card.Content>
      <Image as={Link} to={'events/'+this.props.item.id} floated='right' size='large' src={this.props.item.image} />
      <Card.Header as={Link} to={'events/'+this.props.item.id}>{this.props.item.header}</Card.Header>
      <Card.Meta as={Link} to={'events/'+this.props.item.id}>{this.props.item.meta}</Card.Meta>
      <Card.Content extra>
        <Dropdown icon='ellipsis vertical'>
          <Dropdown.Menu>
            <Dropdown.Item icon='edit' text='Edit' onClick={()=>this.props.onEdit(this.props.item.id)}/>
            <Dropdown.Item icon='delete' text='Delete' onClick={()=>this.props.onDelete(this.props.item.id)}/>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Content>
    </Card.Content>
  </Card>
  }
}

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {items:[]};
  }
  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  addEvent = e => {
    e.preventDefault();
    const db = app.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection('events').add({
      image: this.state.image,
      header: this.state.header,
      meta: this.state.meta,
      extra: this.state.extra
    });  
    this.setState({
      image: '',
      header: '',
      meta: '',
      extra: ''
    });
    this.setState({modalOpen: false});
  };
  onEdit = id => {
    console.log('TODO: edit');
    alert('Sorry, edit is not implemented yet');
  }
  onDelete = id => {
    const db = app.firestore();
    db.collection('events').doc(id).delete();
  };
  onOpen  = () => this.setState({ modalOpen: true })
  onClose = () => this.setState({ modalOpen:false })
  handleChange = (e, { value }) => {this.setState({ value, image: '/event-'+value+'.png' })}
  componentWillMount() {
    const db = app.firestore();
    db.collection('events').onSnapshot(function(dataSnapshot) {
      var items = [];
      dataSnapshot.forEach(function(ds) {
        var data = ds.data();
        data['id'] = ds.id;
        items.push(data);
      });
      this.setState({
        items: items
      });
    }.bind(this));
  }
  render() {
    return (
      <Container>
        <Modal trigger={<Button primary style={{marginBottom:20}} onClick={this.onOpen}>New Event</Button>} open={this.state.modalOpen} onClose={this.onClose}>
        <Modal.Header>Create event</Modal.Header>
          <Modal.Content>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>

              <Form onSubmit={this.addEvent} autocomplete='off'>
              <Form.Field>
                  <input placeholder='Name' name='header' onChange={this.updateInput} value={this.state.header}/>
                </Form.Field>
                <Form.Field>
                <label>Type</label>
                  <Checkbox style={{marginRight:10}} radio label='Birthday' name='event-type' value='birthday' checked={this.state.value === 'birthday'} onChange={this.handleChange} />
                  <Checkbox style={{marginRight:10}} radio label='Baby Shower' name='event-type' value='engagement' checked={this.state.value === 'engagement'} onChange={this.handleChange} />
                  <Checkbox style={{marginRight:10}} radio label='Graduation' name='event-type' value='moving' checked={this.state.value === 'moving'} onChange={this.handleChange} />
                </Form.Field>
                <Form.Field>
                  <TextArea style={{resize:'none'}} placeholder='Description' name='meta' onChange={this.updateInput} value={this.state.meta} />
                </Form.Field>
                <Form.Field>
                  <input placeholder='Extra' name='extra' onChange={this.updateInput} value={this.state.extra}/>
                </Form.Field>
              </Form>                       
                </Grid.Column>
                <Grid.Column>
                  <Image size='medium' src={this.state.image} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button type='submit' positive onClick={this.addEvent}>Submit</Button>
            <Button onClick={this.onClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
        <Card.Group itemsPerRow={4}>
        { this.state.items.map(function(item){
         return  <EventItem item={item} onEdit={this.onEdit} onDelete={this.onDelete} />
        }, this)}
        </Card.Group>
      </Container>
    )
  }
}

export default EventList;